header {
	padding-top: 20px;
	color: #fff;

	img {
		max-height: 80px;
		margin-right: 10px;
	}

	h1 {
		font-size: 2em;
	}

	a {
		text-decoration: none;
		color: #fff;
	}

	p {
		margin-top: 4px;
	}
}

#header-container {
	display: flex;
	justify-content: space-between;
}

#header-left {
	display: flex;

	h1 {
		margin: 0;
		font-size: 1.1em;
	}
}
