.track {
	padding-top: 20px;
	display: flex;

	img {
		max-width: 150px;
		margin-right: 20px;
	}

	h3 {
		color: #fff;
		font-size: 0.9em;
	}
}
