@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

.inner {
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.section-head {
	color: #fff;
	padding-left: 5px;
	margin-bottom: 15px;
	font-size: 1.1em;
	font-family: "Raleway", sans-serif;
}

.playlists {
	display: grid;
	grid-template-columns: repeat(12, auto);
	justify-items: center;
	text-align: center;
	overflow-x: scroll;
}

.playlist {
	margin-bottom: 20px;

	img {
		width: 100px;
		height: 100px;
	}

	h3 {
		color: beige;
		font-size: 0.8em;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}

	p {
		color: #fff;
	}
}

body {
	font-family: "Lato", sans-serif;
	background: #191414;
}

.playlists::-webkit-scrollbar {
	display: none; /* Chrome */
}

.playlists::-webkit-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
