@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
.inner{max-width:90%;margin-left:auto;margin-right:auto}.section-head{color:#fff;padding-left:5px;margin-bottom:15px;font-size:1.1em;font-family:"Raleway", sans-serif}.playlists{display:grid;grid-template-columns:repeat(12, auto);justify-items:center;text-align:center;overflow-x:scroll}.playlist{margin-bottom:20px}.playlist img{width:100px;height:100px}.playlist h3{color:beige;font-size:0.8em;width:90%;margin-left:auto;margin-right:auto}.playlist p{color:#fff}body{font-family:"Lato", sans-serif;background:#191414}.playlists::-webkit-scrollbar{display:none}.playlists::-webkit-scrollbar{-ms-overflow-style:none;scrollbar-width:none}

header{padding-top:20px;color:#fff}header img{max-height:80px;margin-right:10px}header h1{font-size:2em}header a{text-decoration:none;color:#fff}header p{margin-top:4px}#header-container{display:flex;justify-content:space-between}#header-left{display:flex}#header-left h1{margin:0;font-size:1.1em}



#login{height:100vh;position:relative}#login-content{margin:0;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}#login-content img{width:400px;height:400px}#login-content button{display:block;padding:12px 8px;margin:0 auto;background:#1db954;border:none;color:#fff;width:80%;border-radius:3px;cursor:pointer;font-family:"Raleway", sans-serif}

.track{padding-top:20px;display:flex}.track img{max-width:150px;margin-right:20px}.track h3{color:#fff;font-size:0.9em}

